@import "inc/variables";
@import "inc/ckstyles";
@import "inc/admin_bootstrap";

:root
{
    --color-primary: #{$colorAccent};
    --color-students: #{$colorStudents};
    --color-landlords: #{$colorLandlords};
    --color-residential: #{$colorResidential};
    --color-studio: #{$colorStudios};
    --color-secondary: var(--color-primary);
}

@import "../../../Components/Base/BaseComponent/Content/admin.scss";