$la-css-prefix: la;
$la-font-size-base: 1rem;
@import "~line-awesome/dist/line-awesome/scss/line-awesome";

@import "la-fix";
@import "argon/argon.scss";
@import "~cropperjs/src/index.scss";

@import "~tom-select/dist/css/tom-select.bootstrap5";
@import "~dragula/dist/dragula";

@import "pagination";

.ts-control
{
    border-color: #dee2e6;
    color: var(--gray);
    min-height: 46px;
}

.focus .ts-control
{
    border-color: var(--primary);
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(13, 13, 77, 0.1);
}

.input-group .ts-wrapper.form-control
{
    @if $enable-rounded
    {
        border-radius: $input-border-radius;
    }
    @else
    {
        border-radius: 0;
    }
}

.g-sidenav-pinned .sidenav
{
    max-width: 260px !important;
}

body.argon-simple
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.login-logo
{
    width: 100%;
    max-height: 6rem;
}

.card-group-column .card
{
    border-top-width: 0;
    margin-bottom: 0;

    &:first-child
    {
        border-top-width: 1px;
    }
}

.card-group-block
{
    margin-bottom: -1.333rem;

    > *
    {
        display: flex;
    }

    .card
    {
        flex: 1 1 auto;
    }
}

.card .card-header .card-title
{
    display: inline-block;
    margin-top: 0;
}

.card-header-actions
{
    display: inline-block;
    float: right;
    font-size: 1.25rem;
    margin: -.125em 0 -.25em;
    position: relative;

    > .card-header-action
    {
        padding: 0 .25em;
    }

    .dropdown-menu
    {
        left: auto;
        right: 0;

        i
        {
            font-size: 1em;
            vertical-align: 0;
            margin-right: .5em;
        }
    }

    .form-inline
    {
        display: inline-flex;

        &:not(:last-child)
        {
            margin-right: .5rem;
        }

        .form-control
        {
            padding-top: 0;
            padding-bottom: 0;
            height: auto;
        }
    }
}

button.card-header-action
{
    border-color: transparent;
    cursor: pointer;
    background-color: transparent;
    line-height: 1;
    color: $primary;
}

.card-body > .form-group:last-child
{
    margin-bottom: 0;
}

.form-group-static .control-label
{
    font-weight: 600;
    margin-bottom: 0;
}

.footer
{
    padding-left: 30px;
    padding-right: 30px;
}

.flex-fill
{
    display: flex;
    align-items: stretch;

    > *
    {
        flex-grow: 1;
    }
}

.drop-area
{
    cursor: pointer;
    min-height: 180px;
    position: relative;
    width: 100%;
    color: $input-color;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    background-color: #fff;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .drop-area-body
    {
        flex-grow: 1;
        text-align: center;
        padding: 1em 0;
    }

    .image-preview
    {
        text-align: center;

        > img
        {
            max-height: 220px;
        }
    }
}

.field-image-multi
{
    > .drop-area
    {
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        gap: 1rem;

        > .drop-area-body
        {
            grid-column: 1 / -1;
            padding: 2rem 0;
        }

        > input
        {
            display: none;
        }

        > img
        {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

#crop-dialog
{
    img
    {
        max-width: 100%;
    }
}

.btn-inline
{
    display: inline;
    padding: 0;
}

.modal-header
{
    .container-fluid
    {
        padding: 0;
    }
}

button.nav-link
{
    border: 0;
    width: 100%;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
}

.navbar-vertical .navbar-nav .nav-link > i
{
    font-size: 1.333rem;
}

.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img
{
    height: 2rem;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link
{
    padding-top: .25rem;
    padding-bottom: .25rem;
}

img.embed-responsive-item
{
    object-fit: cover;
    object-position: center center;
}

.html-form-control
{
    padding: 0.625rem 0.75rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
}

.custom-control-input:not(:checked) ~ .custom-control-label-primary
{
    display: none;
}

.custom-control-input-primary
{
    display: none;

    &:checked ~ .custom-control-label
    {
        font-weight: bold;
    }

    &:checked ~ .custom-control-label-primary
    {
        display: none;
    }
}

.custom-control-label-primary
{
    cursor: pointer;
    font-size: 0.875rem;
    height: 1rem;
    margin-bottom: 0;
    opacity: .5;
}

.card .table td,
.card .table th
{
    padding-left: .5rem;
    padding-right: .5rem;

    &:first-child
    {
        padding-left: 1.5rem;
    }

    &:last-child
    {
        padding-right: 1.5rem;
    }
}

.th-btn-col
{
    width: 50px;
}

.card-header-actions > .btn:last-of-type,
td .btn:last-of-type
{
    margin-right: 0;
}

.bq-content-trim
{
    height: 5em;
    overflow-x: auto;
}

.d-flex > .card
{
    width: 100%;
}

.card-footer.card-image-footer
{
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    gap: .5rem;
    padding: .5rem .75rem;

    .btn
    {
        margin-right: 0;
    }
}

.reordering-wrapper,
.gu-mirror
{
    .reordering-manual-btn
    {
        display: none !important;
    }
}

.reordering-wrapper
{
    position: relative;

    &.reordering-inprogress:before
    {
        content: "Saving changes...";
        z-index: 1000;
        background-color: rgba(#fff, 0.8);
        color: $colorBaseMid;
        pointer-events: all;
        top: 0;
        left: 15px;
        bottom: 30px;
        right: 15px;
        position: absolute;
        display: flex;
        justify-content: center;
        padding: 1rem;
        align-items: center;
    }

    &.reordering-inprogress.reordering-error:before
    {
        content: "There has been an error re-ordering the items. Please refresh the page and try again.";
        background-color: rgba(#f00, 0.8);
        color: #fff;
    }
}
